import React, { Component } from "react"
import { graphql, Link } from "gatsby"

import Img from "gatsby-image"

import Layout from "../components/layout"
import InnerHero from "../components/heros/inner"

import LogoCarousel from "../components/lists/logoCarousel"

import LogoList from "../components/lists/logoList"

import rics from "../images/logos/rics.png"
import ssip from "../images/logos/ssip.png"
import constructionline from "../images/logos/constructionline.png"

// import SEO from "../components/seo";

export const logos = [{ rics }, { ssip }, { constructionline }]

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  fragment SanityImage on SanityImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
      fluid(maxWidth: 1000) {
        ...GatsbySanityImageFluid
      }
    }
  }
  query ProjectPageQuery {
    logos: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "logos/carousel" }
      }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    posts: allSanityProject(sort: { fields: title, order: ASC }) {
      nodes {
        title
        categories {
          title
        }
        mainImage {
          ...SanityImage
        }
        _rawBody(resolveReferences: { maxDepth: 10 })
      }
    }
    categories: allSanityCategory(sort: { fields: title, order: ASC }) {
      nodes {
        title
      }
    }
    hero: file(relativePath: { eq: "project-hero.jpg" }) {
      ...fluidImage
    }
    servicesImage: file(relativePath: { eq: "ourServices-hero.jpg" }) {
      ...fluidImage
    }
    projectMasterplanning: file(
      relativePath: { eq: "project-masterplanning.png" }
    ) {
      ...fluidImage
    }
    projectResidential: file(relativePath: { eq: "project-residential.png" }) {
      ...fluidImage
    }
    projectFundMonitoring: file(
      relativePath: { eq: "project-fundMonitoring.png" }
    ) {
      ...fluidImage
    }
    projectCommunity: file(relativePath: { eq: "project-community.png" }) {
      ...fluidImage
    }
    projectCommercial: file(
      relativePath: { eq: "project-commercialRetail .png" }
    ) {
      ...fluidImage
    }

    projectEducation: file(relativePath: { eq: "project-education.png" }) {
      ...fluidImage
    }
  }
`

// const ProjectPage = props => {
class ProjectPage extends Component {
  render() {
    return (
      <Layout>
        <InnerHero
          subtitle=""
          title="Our"
          titlegray="Projects"
          img={this.props.data.hero.childImageSharp.fluid}
        />
        <div class="container">
          <div class="mx-auto py-12 max-w-screen-xl lg:py-24">
            <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-20 lg:space-y-0">
              <div class=" space-y-10 sm:space-y-10">
                {/* <p class="text-xl text-gray-500">
                  With over 40 years experience we have gained the expertise and
                  knowledge in every sector so your project can be delivered
                  without mishaps.
                </p> */}
              </div>
              <div class="lg:col-span-2">
                <ul class="space-y-16 sm:space-y-48">
                  <li class="grid sm:grid-cols-3 sm:gap-10 flex">
                    <div class="relative h-116">
                      <Img
                        className="absolute inset-0 object-cover h-full w-full shadow-lg"
                        fluid={
                          this.props.data.projectMasterplanning.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div class="sm:col-span-2 min-h-244 h-full flex flex-col justify-center items-start bg-gray-50 sm:mt-20 p-20">
                      <h3 class="text-gray-200 font-bold  text-3xl sm:text-50 mb-12">
                        Masterplaning
                      </h3>
                      {/* <p class="text-lg text-gray-500 mt-10 mb-20">
                        Ultricies massa malesuada viverra cras lobortis. Tempor
                        orci hac ligula dapibus mauris sit ut eu. viverra cras
                        lobortis. Tempor orci hac ligula dapibus mauris sit ut
                        eu. Eget turpis urna maecenas cras. Nisl dictum.
                      </p> */}

                      <Link to="/masterplanning/" class="btn">
                        View section
                      </Link>
                    </div>
                  </li>
                  <li class="grid sm:grid-cols-3 sm:gap-10">
                    <div class="sm:col-span-1 relative h-116">
                      <Img
                        className="absolute inset-0 object-cover h-full w-full shadow-lg"
                        fluid={
                          this.props.data.projectResidential.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div class="sm:col-span-2 min-h-244 h-full flex flex-col justify-center items-start bg-gray-50 sm:mt-20 p-20">
                      <h3 class="text-gray-200 font-bold text-3xl sm:text-50 mb-12">
                        Residential
                      </h3>
                      {/* <p class="text-lg text-gray-500 mt-10 mb-20">
                        Ultricies massa malesuada viverra cras lobortis. Tempor
                        orci hac ligula dapibus mauris sit ut eu. viverra cras
                        lobortis. Tempor orci hac ligula dapibus mauris sit ut
                        eu. Eget turpis urna maecenas cras. Nisl dictum.
                      </p> */}

                      <Link to="/residential/" class="btn">
                        View section
                      </Link>
                    </div>
                  </li>
                  <li class="grid sm:grid-cols-3 sm:gap-10">
                    <div class="relative h-116">
                      <Img
                        className="absolute inset-0 object-cover h-full w-full shadow-lg"
                        fluid={
                          this.props.data.projectFundMonitoring.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div class="sm:col-span-2 min-h-244 h-full flex flex-col justify-center items-start bg-gray-50 sm:mt-20 p-20">
                      <h3 class="text-gray-200 font-bold text-3xl sm:text-50 mb-12">
                        Fund Monitoring
                      </h3>
                      {/* <p class="text-lg text-gray-500 mt-10 mb-20">
                        Ultricies massa malesuada viverra cras lobortis. Tempor
                        orci hac ligula dapibus mauris sit ut eu. viverra cras
                        lobortis. Tempor orci hac ligula dapibus mauris sit ut
                        eu. Eget turpis urna maecenas cras. Nisl dictum.
                      </p> */}

                      <Link to="/fund-monitoring" class="btn">
                        View section
                      </Link>
                    </div>
                  </li>
                  <li class="grid sm:grid-cols-3 sm:gap-10">
                    <div class="relative h-116">
                      <Img
                        className="absolute inset-0 object-cover h-full w-full shadow-lg"
                        fluid={
                          this.props.data.projectCommunity.childImageSharp.fluid
                        }
                      />
                    </div>
                    <div class="sm:col-span-2 min-h-244 h-full flex flex-col justify-center items-start bg-gray-50 sm:mt-20 p-20">
                      <h3 class="text-gray-200 font-bold text-3xl sm:text-50 mb-12">
                        Community
                      </h3>
                      {/* <p class="text-lg text-gray-500 mt-10 mb-20">
                        Ultricies massa malesuada viverra cras lobortis. Tempor
                        orci hac ligula dapibus mauris sit ut eu. viverra cras
                        lobortis. Tempor orci hac ligula dapibus mauris sit ut
                        eu. Eget turpis urna maecenas cras. Nisl dictum.
                      </p> */}

                      <Link to="/community/" class="btn">
                        View section
                      </Link>
                    </div>
                  </li>
                  <li class="grid sm:grid-cols-3 sm:gap-10">
                    <div class="relative h-116">
                      <Img
                        className="absolute inset-0 object-cover h-full w-full shadow-lg"
                        fluid={this.props.data.hero.childImageSharp.fluid}
                      />
                    </div>
                    <div class="sm:col-span-2 min-h-244 h-full flex flex-col justify-center items-start bg-gray-50 sm:mt-20 p-20">
                      <h3 class="text-gray-200 font-bold text-3xl sm:text-50 mb-12">
                        Hotel and Lesiure
                      </h3>
                      {/* <p class="text-lg text-gray-500 mt-10 mb-20">
                        Ultricies massa malesuada viverra cras lobortis. Tempor
                        orci hac ligula dapibus mauris sit ut eu. viverra cras
                        lobortis. Tempor orci hac ligula dapibus mauris sit ut
                        eu. Eget turpis urna maecenas cras. Nisl dictum.
                      </p> */}

                      <Link to="/hotel-and-leisure/" class="btn">
                        View section
                      </Link>
                    </div>
                  </li>
                  <li class="grid sm:grid-cols-3 sm:gap-10">
                    <div class="relative h-116">
                      <Img
                        className="absolute inset-0 object-cover h-full w-full shadow-lg"
                        fluid={
                          this.props.data.projectCommercial.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                    <div class="sm:col-span-2 min-h-244 h-full flex flex-col justify-center items-start bg-gray-50 sm:mt-20 p-20">
                      <h3 class="text-gray-200 font-bold text-3xl sm:text-50 mb-12">
                        Commercial and Retail
                      </h3>
                      {/* <p class="text-lg text-gray-500 mt-10 mb-20">
                        Ultricies massa malesuada viverra cras lobortis. Tempor
                        orci hac ligula dapibus mauris sit ut eu. viverra cras
                        lobortis. Tempor orci hac ligula dapibus mauris sit ut
                        eu. Eget turpis urna maecenas cras. Nisl dictum.
                      </p> */}

                      <Link to="/commercial-and-retail/" class="btn">
                        View section
                      </Link>
                    </div>
                  </li>
                  <li class="grid sm:grid-cols-3 sm:gap-10">
                    <div class="relative h-116">
                      <Img
                        className="absolute inset-0 object-cover h-full w-full shadow-lg"
                        fluid={
                          this.props.data.projectEducation.childImageSharp.fluid
                        }
                      />
                    </div>
                    <div class="sm:col-span-2 min-h-244 h-full flex flex-col justify-center items-start bg-gray-50 sm:mt-20 p-20">
                      <h3 class="text-gray-200 font-bold text-3xl sm:text-50 mb-12">
                        Education
                      </h3>
                      {/* <p class="text-lg text-gray-500 mt-10 mb-20">
                        Ultricies massa malesuada viverra cras lobortis. Tempor
                        orci hac ligula dapibus mauris sit ut eu. viverra cras
                        lobortis. Tempor orci hac ligula dapibus mauris sit ut
                        eu. Eget turpis urna maecenas cras. Nisl dictum.
                      </p> */}

                      <Link to="/education/" class="btn">
                        View section
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <LogoCarousel logos={this.props.data.logos} /> */}
        <div className="inner py-12 px-4 sm:px-6 lg:py-16 lg:px-8 mt-20 lg:pb-32">
          <p className="text-center text-20 leading-6 font-bold text-black mb-8">
            Accreditations
          </p>
          <div className="max-w-4xl mx-auto grid grid-cols-3 gap-0.5">
            <img
              className="col-span-1 flex justify-center py-8 px-8"
              src={rics}
            />
            <img
              className="col-span-1 flex justify-center py-8 px-8"
              src={ssip}
            />
            <img
              className="col-span-1 flex justify-center py-8 px-8"
              src={constructionline}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default ProjectPage
